import React from 'react';
import { useStyles } from '@wix/tpa-settings/react';
import stylesParams from '../../../../components/Archive/stylesParams';
import { useSelector } from '../../../common/components/runtime-context';
import { getArchiveWidgetData } from '../../aggregated-archive/selectors';
import LinkList from '../link-list-new';
import LinkText from '../link-text';

const Archive = () => {
  const archiveData = useSelector(getArchiveWidgetData);
  const styles = useStyles();
  const monthsDisplayLimit = styles.get(stylesParams.monthsDisplayLimitV2);

  const links = React.useMemo(() => {
    const { archive } = archiveData;

    return archive.slice(0, monthsDisplayLimit).map((archiveLink) => ({
      key: archiveLink.id,
      path: archiveLink.path,
      text: (
        <LinkText
          postCount={archiveLink.display.postCount}
          a11yText={archiveLink.display.a11yText}
        >
          {archiveLink.display.text}
        </LinkText>
      ),
    }));
  }, [archiveData, monthsDisplayLimit]);

  return (
    <nav aria-label={archiveData.ariaLabel}>
      <LinkList links={links} emptyState={{ text: archiveData.emptyState }} />
    </nav>
  );
};

export default Archive;
